.cursor-pointer {
  cursor: pointer;
}
.ck-gke-container-response{
  background-color: #009ef7;
  min-width: 100px;
  border-radius: 5px;
  padding: 3px 5px;
  margin: 0 5px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
}
.custom-pdf-annotate{
  background-color: rgb(82, 86, 89);
  overflow: hidden;
  position: relative;
  padding-top: 50px;
  .custom-toolbar-pdf{
    width: 100%;
    background-color: rgb(50, 54, 57);
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    .tool {
      display: inline-block;
      color: #fff;
      padding: 0px 10px;
    }
  }
  .container-canvas-pdf{
    min-height: 100vh;
  }
  .canvas-container,.pdf-canvas{
    margin: 20px auto;
    overflow: scroll;
  }
  .pdf-canvas{
    .container-fabric-canvas{
      position: absolute !important;
      top:0;
      left: 0;
    }
  }
  
}
.hoverElement{
  .showElement{
    display: none;
    transition: 0.7s display ease-in;
  }
}
.hoverElement:hover{
  .showElement{
    display: unset;
  }
}

.disable-pdf-annotate{
  pointer-events: none;
}
.text-transform-none{
  text-transform: none !important;
}
.fullscreen-container:fullscreen {
  overflow: scroll !important;
}
.fullscreen-container:-ms-fullscreen {
  overflow: scroll !important;
}
.fullscreen-container:-webkit-full-screen {
  overflow: scroll !important;
}
.fullscreen-container:-moz-full-screen {
  overflow: scroll !important;
}

body:fullscreen {
  overflow: scroll !important;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}
//fullcalendar Overriding
.fc .fc-toolbar-chunk > div{
  display: flex;
  align-items: center;
  .fc-toolbar-title{
    margin-left: 15px;
    margin-right: 15px;
    text-transform: uppercase;
    font-size: 18px;
  }
  .fc-next-button,.fc-prev-button{
    background-color: #fff;
  }
}
.fc .fc-toolbar-chunk .fc-button-group{
  display: inline-flex;
}
.fc .fc-button-primary:not(:disabled).fc-button-active{
  background-color: #009ef7;
  color:#fff;
}

.float-to-center{
  position:absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.container-wip-page{
  background-color: #E3CC02;
  height: 100vh;
  width: 100%;
}