//
// Input Group
//

// Form control
.input-group {
  &.input-group-solid {
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    @include border-radius($input-border-radius);

    &.input-group-sm {
      @include border-radius($input-border-radius-sm);
    }

    &.input-group-lg {
      @include border-radius($input-border-radius-lg);
    }

    .input-group-text {
      background-color: transparent;
      border-color: transparent;

      & + .form-control {
        border-left-color: $input-border-color;
      }
    }

    .form-control {
      background-color: transparent;
      border-color: transparent;

      & + .input-group-text {
        border-left-color: $input-border-color;
      }
    }
  }
}
